@import "config.scss";

section.grau,
section.weiss,
section.rot,
.question,
.tipp,
.infobox {
	.wrap {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		@media (max-width: 767px) {
			padding: 0 !important;
		}
	}
	.panel-grid {
		@media (max-width: 767px) {
			padding: 0 !important;
		}
	}
	&.slim {
		margin: 0 auto;
		max-width: 800px;
	}
	overflow: hidden;
	box-sizing: border-box;
	padding: 1.5em !important;
	margin-bottom: 1em;
	background: $c_grau_hell;
	color: $c_copy;
	ul:last-child,
	ul:last-child li:last-child,
	p:last-child {
		margin-bottom: 0;
	}
	.boxtop + h1,
	.boxtop + h2,
	.boxtop + h3 {
		margin-top: 0;
	}
	p:last-child {
		margin-bottom: 0 !important;
	}
	&:last-child {
		margin-bottom: 0;
	}
	&.bold {
		background: $c_link;
		color: white;
		h1, h2, h3, h4 {
			color: white;
		}
		a {
			color: white;
			&:hover {
				color: white;
			}
		}
	}
	&.grau,
	&.rot,
	&.weiss {
		padding: 1.5em;
		background: $c_grau_hell
	}
	&.rot {
		background: $c_rot;
		color: white;
		h1, h2, h3, h4 {
			color: white;
		}
		.button {
			background: $c_copy;
		}
	}
	&.weiss {
		background: white
	}
}

.question,
.tipp {
	background: $c_grau_hell;
}

.question {
	cursor: pointer;
}

.answer {
	display: none;
}
