p+.wp-block-gg-brand {
    margin-top: var(--m-vert);
}

.wp-block-gg-brand {

    position: relative;
    margin-bottom: var(--m-vert);

    .box {
        position: relative;
        z-index: 1986;
        width: 60vw;
        height: 60vw;
        margin: 0 auto calc(-1 * var(--m-vert-slim));
        @media(min-width: 768px){
            position: absolute;
            left: var(--gap-2x);
            top: pxtorem(35);
            width: 260px;
            height: 260px;
            @media(min-width: 1024px){
                left: 0;
                width: 320px;
                height: 320px;
            }
        }
        padding: 1.5em;
        h2 {
            line-height: 1;
            font-weight: bold;
            color: white;
            margin-bottom: 0;
            font-size: pxtorem(20);
            @media(min-width: 768px){
                font-size: pxtorem(32);
            }
        }

        background-color: var(--c-corporate);
    }

    .content {
        padding: calc(var(--m-vert-slim) +  pxtorem(35)) 0 0;
        @media(min-width: 768px){
            margin-left: 81px;
            padding: pxtorem(35) 30px 35px 200px;
            @media(min-width: 1024px){
                padding: pxtorem(35) 30px 35px 278px;
            }
        }
        background: var(--c-hellgrau);
        .brand {
            img {
                width: 100%;
                max-width: 60vw;
                max-width: 120px;
                display: block;
                margin: 0 auto var(--gap);
                &.wide {
                    max-width: 100%;
                }
            }
            @media(min-width: 768px){
                width: 200px;
            }
            @media(min-width: 1024px){
                float: left;
                margin-right: 56px;
            }
        }
        .text-slider {
            @media(min-width: 1024px){
                float: left;
                width: calc(100% - 256px);
            }
            .text {
                padding: 1em pxtorem(35);
                @media(min-width: 768px){
                    padding: 0 0 1em;
                }
                &+.swiper {
                    .swiper-container {
                        width: 100%;
                        overflow: hidden;
                    }
                    max-width: 100%;
                    margin-top: 2em;
                    @media(min-width: 768px){
                        margin-right: var(--m-vert-slim);
                    }
                    .swiper-slide {
                        div {
                            position: relative;
                            width: 100%;
                            padding-bottom: 59%;
                            background-color: white;
                        }
                    }
                }
            }
        }
    }
}