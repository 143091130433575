& + footer {

    max-width: var(--max-width);
    margin: 0 auto;

    .inner {
        @media(min-width: 1024px){
            margin-top: var(--gap-2x);
            padding-top: var(--gap-2x);
            border-top: solid 1px rgba(203, 203, 203, 1.00);
        }
        background: none;
    }
    .topNews {
        h2 {
            color: var(--c-corporate);
            font-weight: bold;
        }
        ul {
            li {
                a {
                    background: none;
                    padding-left: 0;
                    h4 {
                        font-weight: bold;
                    }
                }
            }
        }
        p:last-child {
            padding-left: 0;
            a {
                font-weight: bold;
                text-decoration: none;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                padding-right: 40px;
                min-height: 32px;
                background: url(../images/gg22/Pfeil.svg) no-repeat center right;
            }
        }
    }
    #footerFeatures {
        @media(min-width: 1024px){
            padding-left: var(--gap-2x);
            border-left: solid 1px rgba(203, 203, 203, 1.00);
        }
        h4 {
            font-weight: bold;
        }
        .featuresSmall {
            display: grid;
            gap: var(--gap);
            @media(min-width: 1024px){
                grid-template-columns: 1fr 1fr;
            }
        }
        a {
            box-shadow: none;
            &.featureLarge > span {
                background: none;
                text-align: center;
            }
            &.featureSmall {
                background: none;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: bottom;
                margin-top: 1em;
                @media(min-width: 1024px){
                    margin: 0;
                }
                    padding-bottom: 50%;
                h4 {
                    padding-right: 40px;
                    background: url(../images/gg22/Pfeil.svg) no-repeat bottom right;
                    position: relative;
                    left: auto;
                    top: auto;
                    bottom: auto;
                    display: inline-block;
                    width: auto;
                    margin-bottom: 2em;
                    span {
                        display: block;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    nav {
        background: none !important;
        position: relative;
        .topofpage {
            position: absolute;
            left: 1em;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
        }
    }
}

#rwd_menu {
    @media (max-width: 767px) {
        z-index: 1000000;
        display: block;
        position: fixed;
        left: auto;
        right: 4px;
        bottom: auto;
        top: 4px;
        width: 50px;
        height: 50px;
        border-radius: 31px;
        border: none;
        background: white url("../images/Burger-22.svg") no-repeat center center;
        span {
            display: none;
        }
    }
}