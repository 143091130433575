.wp-block-gg-logoslider {
	.admin {
		padding: 3rem;
		text-align: center;
		font-size: 1.5rem;
	}
	margin: calc(3 * var(--margin-vertical)) 0;
	@media (min-width: 768px) {
		margin: calc(1 * var(--margin)) 0;
	}
	width: 100%;
	.swiper-container {
		margin-top: var(--gap-2x);
	}
	.swiper-wrapper {
		transition-timing-function: linear !important;
	}
	.swiper-slide {
		width: auto !important;
		.image {
			img {
				max-height: 90px;
			}
		}
	}
}
