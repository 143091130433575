.wp-block-gg-zitat {
    &:not(:last-child) {
        margin-bottom: var(--m-vert);
    }
    .image {
        position: relative;
        @media(max-width: 1024px){
            margin-bottom: 2em;
        }
        img:first-child {
            width: 100%;
            &+img {
                position: absolute;
                right: 1em;
                bottom: -1em;
                width: 87px;
                @media(min-width: 1024px){
                    right: 0;
                    bottom: 0;
                    width: auto;
                    transform: translate(12%,12%)
                }
            }
        }
    }
    .content {
        margin-top: 1em;
        .quelle {
            margin-bottom: 0;
        }
    }
}