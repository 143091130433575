#sniffer {

	position: absolute;
	top: -500px;

	z-index: 1;
	@media screen and (min-width: 850px) {
		z-index: 2;
	}
}

.clear {
	clear: both;
}

.printonly {
	display: none;
}

svg,
.wp-caption,
img {
	max-width: 100%;
	height: auto;
}

.notop {
	margin-top: 0;
}

.swiper-container {
	position: relative;
	overflow: hidden;
}