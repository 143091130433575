.wp-block-gg-usps {
    margin-bottom: var(--m-vert);
    position: relative;
    width: 100%;
    padding: var(--m-vert-slim) var(--gap);
    min-height: 64vw;
    @media(min-width: 1024px){
        min-height: auto;
        height: 47vw;
        max-height: 700px;
    }
    background: var(--c-copy);
    overflow: hidden;
    &>img {
        opacity: 0.5;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .inner {
        position: relative;
        z-index: 1986;
        h2 {
            text-align: center;
            color: white;
        }
    }
    .usps {
        margin-top: var(--m-vert);
        margin-left: var(--m-vert);
        margin-right: var(--m-vert);
        display: grid;
        @media(max-width: 767px){
            grid-template-columns: 1fr !important;
        }
        gap: var(--m-vert);
        @media(min-width: 768px){
            gap: var(--m-vert-slim);
        }
        &>div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            .icon {
                position: relative;
                width: 142px;
                height: 142px;
                border-radius: 71px;
                overflow: hidden;
            }
            p {
                max-width: 380px;
                color: white;
                font-weight: bold;
                @media(min-width: 1024px){
                    font-size: pxtorem(24);
                }
                margin-top: 1em;
            }
        }
    }
}