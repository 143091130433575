/* Farben */

$c_rot: #E3000B;
$c_red: $c_rot;

$c_copy: #2A2A2A;
$c_head: #000;
$c_link: $c_rot;

$c_okay: #ADDC91;
$c_warn: #E59E6D;
$c_button: $c_link;

$c_grau_dunkel: #2A2A2A;
$c_grau: #E2E2E2;
$c_grau_hell: #F2F2F2;

$c_divide: #CBCBCB;

/* Font-Familien */

$ff_head: 'interstate', sans-serif;
$ff_copy: 'interstate', sans-serif;
$ff_light: $ff_copy;
$ff_ico: '';

$ff_nav:  $ff_head;
$ff_fixed: "Courier";
$ff_blockquote: $ff_copy;
$ff_form: $ff_head;

$fw_light: 300;
$fw_bold: 500;

/* Font-Groessen */

$fs_copy: 17px;
$fs_fixed: $fs_copy*0.8;
$fs_table: $fs_copy*0.9;
$fs_small: 14px;

/* Dimensionen */

$ww_full: 960px;
$ww_article: $ww_full;
$ww_gap: 20px;

@function pxtorem( $px ) {
    @return ( ( $px ) / ( $fs_copy / 1px ) ) * 1rem;
}

@function pxtoem( $px ) {
    @return ( ( $px ) / ( $fs_copy / 1px ) ) * 1em;
}
