@import "config.scss";

:not(.wp-admin) {

.single,
.search,
.page,
.post {

	form p {
		margin: 20px 0 0 0;
	}

	label {
		font-family: $ff_form;
		display: inline-block;
		padding: 0 0 5px 0;
		font-size: 0.8em;
	}

	textarea,
	input[type="email"],
	input[type="password"],
	input[type="date"],
	input[type="search"],
	input[type="text"] {
		padding: 0.6em 1em;
		box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.30);
		background: none;
		
		background: white;
		margin: 0;
		border: none;
		font-size: 1em;
		font-family: $ff_copy;
		width: 100%;
	}

	input[type="date"] {
		text-transform: uppercase;
	}

	select {
		height: 2.4em;
		padding: 0.6em 1em;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.50);
		border: solid 1px rgba(0, 0, 0, 0.30);
		background: none;
		background: white;
		margin: 0;
		font-size: 1rem;
		width: 100%;
	}

	.so-widget-sow-button .ow-button-base a,
	input[type="submit"],
	a.button,
	.button {
		display: inline-block !important;
		padding: 0.6em 1.3em 0.6em 1.7em !important;
		background: $c_button !important;
		color: white !important;
		text-decoration: none !important;
		font-weight: normal !important;
		border-radius: 0.8em 0 0.8em 0.8em !important;
		font-family: 1rem !important;
		line-height: 1 !important;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.40) !important;
		border: none !important;
		font-family: $ff_copy !important;

		&.ow-button-hover:hover,
		&:hover {
			color: white !important;
			transition: background 1s !important;
			background: darken($c_button, 20%) !important;

			&:active {
				transition: none !important;
				background: darken($c_button, 20%) !important;
			}
		}

		&[href$=".pdf"] {
			padding: 0.8em 1.3em !important;
			background-image: url(../images/IconPDF.png) !important;
			background-repeat: no-repeat !important;
			background-position: 15px 11px !important;
			background-size: 22px 26px !important;
			padding-left: 47px !important;
		}
	}

	textarea,
	input[type="checkbox"],
	input[type="radio"],
	input[type="submit"],
	input[type="button"],
	select {
		cursor: pointer;
	}

	div.wpcf7-response-output {
		clear: both;
		margin: 1em 0;
	}

	div.wpcf7-validation-errors,
	div.wpcf7-acceptance-missing {
		border: none;
		background-color: $c_rot;
		color: white;
		padding: 0.6em 1em;
	}

	span.wpcf7-not-valid-tip {
		display: block;
		margin-top: 0.5em;
		color: $c_rot;
	}

	span.wpcf7-list-item-label {
		display: inline-block;
		width: calc(100% - 60px);
		margin-left: 0.8em;
		vertical-align: top;
	}

	form#searchform {
		margin-bottom: 2em;

		#s {
			width: calc(100% - 120px);
		}

		.button {
			width: 100px;
			float: right;
		}
	}

	header form#searchform {
		display: none;
		clear: both;
		padding-top: 1em;
		margin: 0;
	}

}
}
