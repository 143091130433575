table {
    width: 100%;
    border: 1px solid #dddddd;
    background: white;
    font-size: $fs_table;
}

td,
th {
    border-top: solid 1px #dddddd;
    border-left: solid 1px #dddddd;
    padding: 0.2em 0.6em;
}

th {
    border-top: none;
    background: #000;
    color: white;
    font-family: $ff_head;
}

th:first-child,
td:first-child {
    border-left: none;
}

tbody tr:nth-child(odd) td {
    background-color: #eeeeee;
}