.wp-block-gg-bild-text {
    position: relative;
    align-items: stretch;
    height: 100%;
    display: flex;
    &.reverse:not(.column) {
        flex-direction: row-reverse;
    }
    @media(max-width: 1023px){
        flex-direction: column;
    }
    &.column {
        flex-direction: column;
        &.reverse {
            flex-direction: column-reverse;
        }
    }
    .bild {
        position: relative;
        img {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }
    &.column {
        .bild {
            flex-shrink: 0;
            width: 100%;
        }
    }
    &:not(.column) {
        @media(min-width: 1024px){
            .bild {
                flex-shrink: 0;
                width: calc(50% - var(--gap));
                width: 50%;
            }        
        }
    }
}

.wp-block-columns:has(.wp-block-gg-bild-text) {
        .wp-block-column+.wp-block-column {
        @media(max-width: 1023px){
            margin-top: var(--m-vert);
        }
    }
    @media(max-width: 1023px){
        display: flex;
        flex-wrap: wrap !important;
        gap: 0;
        .wp-block-column {
            width: 100%;
            flex-basis: 100% !important;
        }
    }
    gap: var(--gap-2x);
    margin-bottom: var(--gap-2x) !important;
}
.wp-block-column:has(.wp-block-gg-bild-text) {
    background: var(--c-hellgrau);
    .wp-block-gg-bild-text {
        .text {
            padding: var(--gap-2x);
            ul {
                font-size: 0.87em;
                font-weight: bold;
            }
        }
    }
}