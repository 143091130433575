.wp-block-gg-services {
   padding: var(--m-vert-slim) 0;
    background: var(--c-hellgrau);
    margin-bottom: var(--m-vert);
    .intro,
    .services {
        @extend .wr;
    }
    .intro {
        text-align: center;
    }
    .services {
        margin-top: var(--m-vert);
        display: grid;
        gap: var(--gap-2x);
        @media(min-width: 1024px){
            gap: pxtorem(98) pxtorem(176);
        }
        @media(min-width: 768px){
            grid-template-columns: 1fr 1fr;
        }
        @media(min-width: 1024px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        &>div.inner {
            display: flex;
            flex-direction: column;
            .icon {
                align-self: center;
                width: 60%;
                padding-bottom: 60%;
                @media(min-width: 768px){
                    width: 76%;
                    padding-bottom: 76%;
                }
                position: relative;
                border-radius: 200px;
                overflow: hidden;
                border: solid 1px var(--c-mittelgrau);
            }
            h2 {
                position: relative;
                z-index: 1986;
                font-size: pxtorem(24);
                line-height: 1;
                width: 100%;
                padding: 1em 0 0.6em;
                margin: -1.3em 0 0 0;
                @media(min-width: 768px){
                    margin: -1.3em 0 1em 0;
                }
                background: var(--c-corporate);
                color: white;
                text-align: center;
            }
            p {
                font-size: var(--fs-small);
            }
        }
    }
}