@import "ui";

.gg22 {
    .wp-block-group.wide,
    .content {
        hyphens: none;
        &>.container,
        &>.post-password-form,
        &>p,
        &>ul,
        &>ol,
        &>h1,
        &>h2,
        &>h3,
        &>h4,
        &>h5,
        &>h6,
        &>.wp-block-image,
        &>.wp-block-gg-persons,
        &>.wp-block-gg-slider,
        &>div.wp-block-group,
        &>.wp-block-columns {
           @extend .wr;
        }
        &>.wp-block-gg-slider,
        &>.wp-block-columns {
            margin: 0 auto var(--m-vert);
        }
        .wp-block-column+.wp-block-column {
            @media(max-width: 781px){
                margin-top: var(--m-vert);
            }
        }
        &:not(.wp-block-group)>h2+p:not(.abstract) {
            margin-top: 0.86em;
            margin-left: auto;
            margin-right: auto;
            max-width: 750px;
        }
    }
    .wp-block-columns:last-child {
        margin-bottom: 0;
    }
}