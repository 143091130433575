.wp-block-gg-redbox,
.wp-block-gg-red-box {
    background-color: var(--c-corporate);
    color: white;
    padding: var(--gap-2x);
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .h3 {
        @extend .h2;
        color: white !important;
        margin-bottom: 0;
        &+span {
            width: 33%;
            display: block;
            margin: 1.8em 0 2em;
            background: white;
            height: 0.6em;
        }
    }
    p.link {
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        svg {
            margin-left: 0.7em;
            margin-bottom: 0.4em;
        }
        a {
            color: white;
            text-decoration: none;
        }
    }
}