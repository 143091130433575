.wp-block-gg-bubbles {
    padding: 0 !important;
    width: 100%;
    overflow: hidden;
    @media(max-width: 1024px){
        max-width: none !important;
    }
    .bubbles {
        position: relative;
        display: flex;
        &.image {
            min-width: 960px;
            justify-content: space-around;
        }
        &:not(.image) {
            flex-direction: column;
            align-items: center;
        }
        @media(min-width: 768px){
            flex-direction: row !important;
            justify-content: space-around !important;
        }
        margin: var(--m-vert) 0;
        a.bubble:hover {
            &>div>div {
                transform: scale(1.1);
                border-color: white;
                background: $c_rot;
                color: white !important;
                h2 {
                    color: white !important;
                }
            }
        }
        .bubble {
            position: relative;
            z-index: 2;
            display: block;
            width: 50vw;
            width: clamp(200px,50vw,200px);
            @media(min-width: 768px){
                &:not(.main) {
                    padding: 0 0 clamp(16px, 5vw, 80px) 0;
                }
                align-self: flex-start;
                width: clamp(150px,20%,200px);
            }
            @media(min-width: 1440px){
                padding: 0 0 clamp(16px, 5vw, 80px) 0;
            }
            &:nth-child(2n) {
                @media(min-width: 768px){
                    &:not(.main) {
                        padding: clamp(16px, 5vw, 80px) 0 0 0;
                    }
                    align-self: flex-end;
                }
                @media(min-width: 1440px){
                    
                    padding: clamp(16px, 5vw, 80px) 0 0 0;
                }
                img {
                    @media(max-width: 767px){
                        right: 0;
                        left: auto;
                        transform: translate(50%,-50%);
                    }
                    @media(min-width: 768px){
                        top: auto;
                        bottom: 0;
                        transform: translate(-50%,50%);
                    }
                }
            }
            &>div {
                position: relative;
                width: 100%;
                padding-bottom: 100%;
                &>div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    border: solid 4px $c_rot;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    text-transform: uppercase;
                    background: rgba(255,255,255,1);
                    h2 {
                        font-size: 1.2em;
                        @media(min-width: 1024px){
                            font-size: 1em;
                        }
                    }
                }
            }
            img {
                @media(max-width: 374px){
                    display: none;
                }
                position: absolute;
                @media(max-width: 767px){
                    left: 0;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 50%;
                }
                @media(min-width: 768px){
                    top: 0;
                    left: 50%;
                    max-width: 70%;
                    transform: translate(-50%,-50%);
    
                }
            }
        }
        svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            line {
                stroke: $c_rot;
                stroke-width: 4px;
            }
        }
    }
    &:not(.image) {
            margin-top: var(--m-vert);
            margin-bottom: var(--m-vert);
        mask {
            display: none;
        }
        .bubble {
            @media(max-width: 767px){
                &:not(:first-child) {
                    margin-top: var(--m-vert-slim);
                }
                &:nth-child(2n) {
                    margin-left: var(--m-vert)
                }
                &:nth-child(2n+1) {
                    margin-right: var(--m-vert)
                }
            }
        }
    }
    &.image {
        position: relative;
        margin: 0;
        width: 100%;
        margin-bottom: var(--m-vert);
        .image {
            position: relative;
            width: 100%;
            padding-bottom: 100vw;
            @media(min-width: 768px){
                padding-bottom: 50%;
            }
            @media(min-width: 1024px){
                padding-bottom: 27%;
            }
        }
        .bubbles {
            margin: 0;
            padding: 2em 0;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
        }
        .bubble {
            &.main {
                width: clamp(180px,50vw,240px);
                &>div>div {
                    background: $c_rot;
                    color: white !important;
                    h2 {
                        color: white !important;
                        font-size: 1.3em;
                    }
                }
                @media(min-width: 768px){
                    width: clamp(150px,24%,220px);
                }
            }
            &:not(.main) {
                width: clamp(120px,35vw,180px);
                @media(min-width: 768px){
                    width: clamp(100px,15vw,150px);
                    &>div>div {
                        background: rgba(255,255,255,0.6);
                        h2 {
                            font-size: 0.86em;
                        }
                    }
                }
            }
        }
    }
}