.wp-block-gg-bereich {
    .bild {
        width: 100%;
        padding-bottom: 120%; 
        position: relative;
        margin-bottom: var(--gap);
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    a, span {
        text-decoration: none;
        color: var(--c-copy);
        font-weight: bold;
        display: flex;
        align-items: center;
        line-height: 1;
        svg {
            margin-left: 0.5em;;
                fill: var(--c-corporate);
        }
    }
}

.wp-block-gg-feature {
    .bild {
        width: 100%;
        padding-bottom: 73%; 
        position: relative;
        margin-bottom: var(--gap);
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}