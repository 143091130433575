@import "config.scss";

.jPanelMenu-panel {
	@media screen and (min-width: 850px) {
		display: none;
	}
}

@keyframes kenburns {

	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.1);
	}

}

#wrapall > header {

	display: flex;
	
	&.fixed {
		@media (min-width: 768px) {
			position: fixed;
			width: 100% !important;
			background: white;
			left: 0;
			top: 0;
			box-shadow: 0 0 12px rgba(0,0,0,0.2);
			a.logo {
				margin-top: 15px;
				margin-bottom: 7px;
				width: 141px;
				height: 75px;
			}
		}
	}

	position: relative;
	top: 0;
	z-index: 1989;
	width: auto !important;

	border-bottom: solid 1px $c_divide;

	@media (min-width: 768px) {
		border: none;
		margin: 0 0 20px 0;
	}

	.wrap {
		text-align: left;
	}

	a {
		text-decoration: none;
		&.logo {
			display: block;
			margin: 10px 0;
			@media (min-width: 768px) {
				float: left;
				margin: 34px 43px 0 0;
			}
			width: 161px * 0.9;
			height: 99px * 0.9;
			@media (min-width: 1024px) {
				width: 230px;
				height: 122px;
			}
			background: url("../images/GarantGruppe.jpg") no-repeat;
			background-size: 100% 100%;
			text-indent: -5000px;
		}
	}

	.wrap {

		position: relative;
		display: inline-block;
		width: 100%
		;
	}

	nav {

		display: none;

		@media (max-width: 1023px) {
			font-size: 0.9em;
		}
		@media (min-width: 768px) {
			display: block;
		}

		position: absolute;

		&.main {
			bottom: 0;
			right: 28px;
		}

		&.secondary {
			top: 0;
			right: 0;
			background: $c_grau;
			border-radius: 0 0 21px 21px;
			padding: 10px 29px;
			a {
				color: $c_grau_dunkel;
				&:hover {
					color: $c_rot;
				}
			}
		}

		a {
			color: $c_copy;
			&:hover {
				color: $c_link;
			}
		}

		& > ul {
			@media (max-width: 799px) {
				font-size: 0.95em;
			}
			@media (min-width: 1280px) {
				font-size: pxtorem(20);
			}
			& > li {
				position: relative;
				float: left;
				margin-left: pxtoem(27);
				padding-bottom: 10px;
				&:first-child {
					margin: 0;
				};
				&.current-menu-item,
				&.current-menu-ancestor {
					& > a {
						color: $c_link;
						font-weight: $fw_bold;
					}
				}
				& > ul {
					display: none;
					position: absolute;
					left: 0;
					top: calc( 1em + 10px );
					box-shadow: 0 0 6px 0 rgba(0,0,0,0.09);
					background: white;
					border-radius: 0 26px 0 0;
					padding: 1em 0.9em;
					a {
						display: block;
						padding: 0.3em 0 0.3em 1.7em;
						text-decoration: none;
						color: $c_copy;
						background: url("../images/ArrowRechtsSolo.svg") no-repeat left center;
						background-size: 22px 22px;
						&:hover {
							background-image: url("../images/ArrowRechts.svg");
							color: $c_rot;
						}
						white-space: nowrap;
					}
				}
				&:hover {
					& > ul {
						display: block;
					}
				}
			}
		}

		&.secondary {
			margin-right: 1em;
			& > ul {
				@media ( max-width: 1023px ) {
				}
				& > li {
					display: inline-block;
					vertical-align: middle;
					margin-left: 15px;
					font-size: pxtorem(16);
					&.wpml-ls-item {
						padding-top: 2px;
					}
					&:first-child {
						margin: 0;
					}
					&.kontakt a,
					&.suche a,
					&.telefon a {
						display: inline-block;
						vertical-align: middle;
						height: 19.5px;
						background-size: contain;
						background-position: left center;
						background-repeat: no-repeat;
						margin-left: 1em;
						span {
							display: inline-block;
							vertical-align: middle;
							&:first-child {
								width: 31.5px;
								height: 100%;
							}
						}
					}
					&.social a {
						display: inline-block;
						vertical-align: middle;
						width: 19.5px;
						height: 19.5px;
						background-size: contain;
						background-position: left center;
						background-repeat: no-repeat;
						text-indent: -5000px;
					}
					&.facebook a {
						background-image: url("../images/Facebook.svg");
					}
					&.instagram a {
						background-image: url("../images/Instagram.svg");
					}
					&.xing a {
						background-image: url("../images/XING.svg");
					}
					&.linkedin a {
						background-image: url("../images/LinkedIn.svg");
					}
					&.rss a {
						background-image: url("../images/RSS.svg");
					}
					&.kontakt a {
						background-image: url("../images/EMail.svg");
					}
					&.language a {
						background-image: url("../images/Bubble.svg");
					}
					&.telefon a {
						background-image: url("../images/Phone.svg");
					}
					&.suche a {
						background-image: url("../images/Suche.svg");
					}
				}
			}
		}

	}

	#mobile_menu {
		display: none;
	}

}

.sidr {

	background: white;
	font-weight: $fw_light;
	padding: 1em 0 110px 0;
	border-left: solid 1px $c_grau;

	a {
		padding: 0.5em 1em;
		display: block;
		border-bottom: solid 1px rgba(255,255,255,0.5);
		text-decoration: none;
		color: $c_copy;
		font-size: 1.2rem;
		&.sidr-class-logo {
			display: block;
			margin: 10px auto;
			padding: 0;
			border: none;
			@media (min-width: 768px) {
				float: left;
				margin: 34px 43px 0 0;
			}
			width: 161px * 0.9;
			height: 99px * 0.9;
			@media (min-width: 1024px) {
				width: 230px;
				height: 122px;
			}
			background: url("../images/GarantGruppe.svg") no-repeat;
			background-size: 100% 100%;
			text-indent: -5000px;
		}
	}

	.sidr-inner {

		padding: 0 1.2em

	}


	.sidr-inner > ul {
		padding-top: 1em;
		& > li {
			& > ul {
				display: none;
				margin-bottom: 0.5rem;
				& > li {
					a {
						font-size: 1rem;
						padding-left: 2em;
					}
				}
			}
			&.show {
				& > a {
					font-weight: $fw_bold;
					color: red;
				}
				& > ul {
					display: block;
				}
			}
		}
	}

}

.sidr {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999999;
    width: 260px;
    overflow-x: hidden;
    overflow-y: auto
}
.sidr.sidr-right {
    left: auto;
    right: -260px;
}
.sidr.sidr-left {
    left: -260px;
    right: auto;
}

/************************************************************************************************/
//
// ! Fixed
//

@keyframes fixed {

	0% {
		top: -200px;
	}

	100% {
		top: -60px;
	}

}

body.fixed {

	@media (min-width: 960px) {

		header {
			position: fixed;
			width: 100% !important;
			max-width: 1280px;
			transition: all 1s;
			top: -60px;
			animation: fixed 1s;
			h1 {
				a {
				}
			}
		}

		section#content {
			padding-top: 167px;
		}

	}

}

// More

.titelbild {

	margin-bottom: 2em;
	img {
		width: 100%;
		max-width: none;
		border-radius: 0 0 6vw 0;
	}

}
