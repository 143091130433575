.wp-block-gg-dropbox {
    position: relative;
    @media(max-width: 1023px){
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .drop {
        width: 193px;
        height: 193px;
        position: relative;
        z-index: 1986;
        margin-bottom: calc(-1 * var(--m-vert-slim));
        @media(min-width: 1024px){
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 262px;
            height: 262px;
        }
        border-radius: 131px 0 131px 131px;
        text-align: center;
        line-height: 1;
        h2 {
            font-weight: bold;
            color: white;
            font-size: pxtoem(24);
            @media(min-width: 1024px){
                font-size: pxtoem(27);
            }
        }
        background-color: var(--c-corporate);
    }
    .dropbox {
        background: var(--c-hellgrau);
        padding: calc(2em + var(--m-vert-slim)) 2em 2em;
        @media(min-width: 1024px){
            margin-left: 131px;
            padding: 35px 21px 35px 202px;
        }
        div {
            margin-bottom: var(--m-vert-slim);
            p {
                padding-left: pxtoem(40);
                background: url("../images/gg22/Check.svg") no-repeat left top;
                @media(max-width: 1023px){
                    background-size: 25px 25px;
                }
                strong {
                    color: var(--c-corporate);
                }
            }
        }
        .button {
            text-align: center;
        }
    }
}