.wp-block-gg-contactform {

    margin-top: 150px;

    @media(min-width: 1024px){
        margin-top: 0;
        padding: var(--m-vert-slim);
        background: var(--c-hellgrau);
    }

    h2.in-drop {
        margin-bottom: 0.3em !important;
    }

    h2:not(.in-drop),
    .h2:not(.in-drop) {
        text-align: center;
        color: var(--c-copy);
        font-weight: normal;
        margin-bottom: 1em;
        strong {
            color: var(--c-corporate);
            font-weight: bold;
        }
        &.top {
            @media(max-width: 1023px){
                display: none;
            }
        }
        &:not(.top) {
            @media(min-width: 1024px){
                display: none;
            }
        }
    }

    &>div {
        margin: var(--m-vert) auto 0;
        padding: var(--m-vert-slim);
        @media(max-width: 1023px){
            .drop {
                margin: -95px auto 0 !important;
                h2 {
                    padding: 0 1em;
                }
                &+h2 {
                    margin: 1em 0;
                    br {
                        display: none;
                    }
                }
            }
        }
        background: var(--c-hellgrau);
        max-width: 1000px;
        @media(min-width: 1024px){
            padding: 0;
            display: flex;
        }
        a {
            text-decoration: none;
        }
        .drop {
            flex-shrink: 0;
            margin-right: var(--gap-4x);
        }
        .form {
            strong {
                color: var(--c-corporate);
            }
            flex-grow: 1;
            form {
                padding: var(--gap-2x) 0 0;
                p {
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                    span {
                        display: block;
                    }
                    .or {
                        padding: 0.6em 1em;
                    }
                }
            }
            
            label {
                font-size: 1em;
                &>span {
                    display: block;
                    margin-top: 0.3em;
                }
            }
            input[type="text"],
            input[type="email"] {
                font-size: 1em;
                padding: 0.5em;
                border: solid 1px rgba(0,0,0,0.3);
                box-shadow: inset 0 1px 3px rgba(0,0,0,0.3);
            }
        }
    }

}