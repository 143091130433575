.wp-block-gg-persons {
    margin-bottom: var(--m-vert-slim);
    .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media(min-width: 600px){
        }
        @media(min-width: 960px){
        }
        @media(min-width: 1440px){
            max-width: var(--max-width);
        }
    }
    .person {
        margin-bottom: var(--gap);
        display: flex;
        &.has-trivia img {
            cursor: pointer;
        }
        width: 100%;
        @media(min-width: 600px){
            width: 50%;
        }
        @media(min-width: 960px){
            width: 25%;
        }
        .image {
            flex-shrink: 0;
            position: relative;
            width: 86px;
            height: 86px;
            @media(min-width: 1440px){
                width: 100px;
                height: 100px;
            }
            margin-right: var(--gap);
            img {
                width: 100%;
            }
        }
        .details {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-between;
            padding: 2px var(--gap) 2px 0;
            @media(min-width: 1440px){
                font-size: 1.1em;
            }
            &>div {
                &.comms {
                    display: flex;
                    p+p {
                        margin-left: 10px;
                    }
                }
                p {
                    margin: 0;
                    flex-grow: 0;
                    font-size: 0.86em;
                    &.name {
                        font-weight: bold;
                    }
                    &:not(.name) {
                        font-size: 0.6em;
                    }
                    &.comms {
                        a {
                            white-space: nowrap;
                            display: flex;
                            align-items: center;
                            color: var(--c-copy);
                            text-decoration: none;
                            img {
                                margin-right: 6px;
                            }
                            &:hover {
                                color: var(--c-corporate);
                            }
                        }
                    }
                }
            }
        }
        .personal {
            font-size: 0.8em;
            display: none;
            &.show {
                display: block;
            }
            position: fixed;
            z-index: 19890;
            background-color: var(--c-hellgrau);
            h3 {
                color: var(--c-corporate);
            }
            left: var(--gap-2x);
            right: var(--gap-2x);
            top: 50%;
            max-height: 80vh;
            transform: translateY(-50%);
            .texthidden {
                opacity: 0;
                padding: var(--gap-2x);
            }
            .text {
                padding: var(--gap-2x);
                overflow: scroll;
            }
            @media(min-width: 960px){
                left: 50%;
                transform: translate(-50%,-50%);
                width: 720px;
                height: auto;
                max-height: calc(100vh - var(--gap-4x));
            }
            .close {
                cursor: pointer;
                position: absolute;
                width: 24px;
                height: 24px;
                background: var(--c-corporate);
                left: 0;
                top: 0;
                transform: translate(-6px,-6px);
                &:hover {
                    background: var(--c-copy);
                }
            }
        }
    }
}