@import "blocks/slides";
@import "blocks/usps";
@import "blocks/zitat";
@import "blocks/zitat-slider";
@import "blocks/dropbox";
@import "blocks/brand";
@import "blocks/services";
@import "blocks/linkliste";
@import "blocks/contactform";
@import "blocks/cta";
@import "blocks/persons";
@import "blocks/header";
@import "blocks/bubbles";
@import "blocks/bild-text";
@import "blocks/logo-slider";
@import "blocks/bereich";
@import "blocks/tagcloud";
@import "blocks/red-box.scss";

.uc {
    border: solid 1px $c_rot;
    padding: 3em;
    margin: 2em 0;
    text-align: center;
    line-height: 1;
}