@font-face {
    font-family: "Maax";
    font-weight: 300;
    src: url("./fonts/Maax-Light.otf") format("opentype");
}

@font-face {
    font-family: "Maax";
    font-weight: 400;
    src: url("./fonts/Maax-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Maax";
    font-weight: 700;
    src: url("./fonts/Maax-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Maax";
    font-weight: 900;
    src: url("./fonts/Maax-Black.otf") format("opentype");
}