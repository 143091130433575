.wr {
    @media(max-width: 1280px) {
        &:not(.full-width) {
            // padding: 0 var(--gap-2x);
        }
    }

    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;

}

#content.gg22,
.page.gg22,
.single.gg22,
.page.gg22,
.post.gg22 {

    .drop {
        width: 193px;
        height: 193px;
        position: relative;
        z-index: 1986;
        margin-bottom: calc(-1 * var(--m-vert-slim));

        @media(min-width: 1024px) {
            &.abs {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            width: 262px;
            height: 262px;
        }

        border-radius: 131px 0 131px 131px;
        text-align: center;
        line-height: 1;

        h2 {
            font-weight: bold;
            color: white;
            font-size: pxtoem(24);

            @media(min-width: 1024px) {
                font-size: pxtoem(27);
            }
        }

        background-color: var(--c-corporate);
    }

    input[type="submit"],
    a.button,
    .button {
        font-family: var(--ff-copy) !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        -webkit-appearance: none;
        &.more {
            padding: 0 pxtoem(36) 0 0 !important;
            //background: transparent url("../images/gg22/More.svg") no-repeat right center !important;
            //background-size: pxtoem(20) pxtoem(20) !important;
            background: transparent url("../images/gg22/Pfeil.svg") no-repeat center right !important;
            color: var(--c-corporate) !important;
            &:hover {
                color: var(--c-copy) !important;
            }
        }
        &.simple {
            font-weight: bold;
            padding: 0.4em 0.4em 0.2em 0.4em !important;
            box-shadow: none !important;
            font-weight: bold !important;
        }
    }

    span.line {
        display: block;
        width: 50%;
        height: pxtorem(12);
        background: white;
        margin: 1em 0;

    }

    .swiper-pagination  {
        bottom: 0.6em;
        .swiper-pagination-bullet {
            width: pxtorem(8);
            height: pxtorem(8);
            margin: 0 pxtorem(4);
            @media(min-width: 1024px){
                width: pxtorem(13);
                height: pxtorem(13);
                margin: 0 pxtorem(8);
            }
            background: white;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: var(--c-corporate);
            }
        }
        &.on-white {
            .swiper-pagination-bullet {
                border: solid 1px rgba(0, 0, 0, 0.24);
                &.swiper-pagination-bullet-active {
                    border: solid 1px rgba(255, 255, 255, 0.24);
                }
            }
        }
    }

}

.acf-admin-notice {
    &:not(:first-child) {
        margin-top: var(--m-vert);
    }
    background: rgba(0,0,0,0.1);
    padding: var(--m-vert-slim);
}

.swiper-container {
    overflow: hidden;
}


.upload {
    position: relative;
    &.pa1 {
        position: absolute;
    }
    button {
        position: absolute;
        right: 1em;
        top: 1em;
        z-index: 1986;
        background: white;
    }
    .note {
        margin: 0 !important;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 1em 0;
    }
}