// Kacheln
section.tiles {
    &.textBottom {
        font-size: 0.75em;
    }
    &.rwdStacked {
        @media (max-width: 767px) {
            .tileContainer {
                width: 100% !important;
                margin-left: 0 !important;
                padding-bottom: 100% !important;
                .tile {}
            }
        }
    }
    .tileContainer {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        &.halfHeight {
            padding-bottom: 50%;
        }
        margin: 0 0 5px 0;
        @media (min-width: 768px) and (max-width: 1023px) {
            font-size: 0.7em;
        }
        @media (min-width: 1024px) and (max-width: 1279px) {
            font-size: 0.8em;
        }
        @media (min-width: 375px) and (max-width: 767px) {
            font-size: 0.7em;
            float: left;
            width: calc( 50% - 5px);
            padding-bottom: calc( 50% - 5px);
            &.halfHeight {
                padding-bottom: calc( 25% - 5px);
            }
            margin: 0 0 5px 5px;
            &:nth-child(2n+1) {
                margin-left: 0;
            }
        }
        @media (min-width: 768px) {
            float: left;
            width: calc( 33.3333% - 3.3333px);
            padding-bottom: calc( 33.3333% - 3.3333px);
            &.halfHeight {
                padding-bottom: calc( 16.6666% - 5px);
            }
            margin: 0 0 5px 5px;
            &:nth-child(3n+1) {
                margin-left: 0;
            }
        }
        .tile {
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            overflow: hidden;
            &:first-child {
                bottom: auto;
                top: 0;
            }
            width: 100%;
            height: 100%;
            &.half {
                height: calc( 50% - 2.5px);
                &:first-child {
                    margin-bottom: 5px;
                }
            }
            background-color: $c_grau;
            background-size: cover;
            text-decoration: none;
            color: $c_copy;
            h4 {
                margin-top: 0;
                font-weight: $fw_light;
                font-size: pxtoem(28);
                line-height: 1.3;
                span {
                    background: white;
                    display: inline-block;
                    padding: 0.05em 0.5em 0.05em 0.3em;
                    margin: 0 0 0.3em 0;
                }
            }
            &>div {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                &.text {
                    padding: 1.2em;
                    position: relative;
                    height: 100%;
                    &.textBottom {
                        height: auto;
                        position: absolute;
                        left: 0;
                        top: auto;
                        bottom: 0;
                        p {
                            background: white;
                            display: inline-block;
                            padding: 0.10em 0.7em 0.10em 0.7em;
                        }
                    }
                    img.logo {
                        position: absolute;
                        left: 1.2em;
                        top: 1.2em;
                        &.right {
                            left: auto;
                            right: 1.2em;
                        }
                        &.bottom {
                            top: auto;
                            bottom: 1.2em;
                        }
                    }
                }
            }
            .bild {
                background-color: $c_grau_hell;
                background-size: cover;
                background-position: center;
                transition: transform 0.2s;
            }
            &:hover {
                .bild {
                    transform: scale(1.1);
                    transition: transform 0.5s;
                }
            }
        }
    }
    .inhaltWrap {
        background-size: cover;
        @media (min-width: 1024px) {
            background-attachment: fixed;
        }
        background-repeat: no-repeat;
        background-position: center center;
        .wrap>.coltop+h2 {
            font-size: pxtorem(32);
            @media (min-width: 560px) {
                font-size: pxtorem(48);
            }
        }
        .close {
            position: absolute;
            left: 50px;
            top: 53px;
            height: 50px;
            width: 50px;
            span {
                position: absolute;
                left: 0;
                top: 50%;
                height: 1px;
                width: 100%;
                background: $c_rot;
                transition: transform 2s;
            }
        }
        &.show {
            .close {
                cursor: pointer;
                span {
                    transform: rotate(45deg);
                    &+span {
                        transform: rotate(-45deg);
                    }
                    transition: transform 2s;
                }
            }
        }
        ul {
            li {
                background: url("../images/Bullet.svg") no-repeat 0 7px;
                padding-left: 1.3em;
            }
        }
    }
}

article#copy section.tiles {
    .inhaltWrap {
        padding: 0 1em;
        .wrap {
            padding: 1em 0;
        }
        h1 {
            position: relative;
        }
        .close {
            position: absolute;
            z-index: 1;
            left: auto;
            right: 1em;
            top: 1em;
            height: 2em;
            width: 2em;
        }
    }
}

// Module
section.module {
    margin: 22px auto 12px;
    @media (max-width: 1023px) {
        padding: 0 20px 50px !important;
    }
    &>div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (min-width: 768px) {
            flex-wrap: nowrap;
            font-size: 0.875em;
        }
    }
    a {
        display: block;
        color: $c_copy;
        text-decoration: none;
        float: left;
        width: calc( 50% - 5px);
        margin: 0 0 10px;
        font-size: 0.8em;
        @media (min-width: 414px) {
            font-size: 1em;
        }
        @media (min-width: 768px) {
            width: calc( 33.3333% - 6.666px);
        }
        @media (min-width: 1024px) {
            width: calc( 20% - 8px);
        }
        line-height: 1.3;
        span {
            display: block;
            width: 100%;
            padding-bottom: 53%;
            margin-bottom: 10px;
            background-size: cover;
        }
        p {
            padding: 0 5px;
        }
    }
}

body.home section.module h1 {
    display: none;
}

// Services
section.services {
    div {
        text-align: left;
        margin: 0 0 20px;
        &.clear {
            margin: 0;
        }
        a {
            display: block;
            position: relative;
            background: $c_grau;
            background-position: center center;
            background-size: cover;
            text-decoration: none;
            float: left;
            padding: 5px 5px 15% 5px;
            @media (min-width: 568px) {
                padding: 20px;
            }
            margin-bottom: 5px;
            p {
                margin: 0.5em 0 0;
                color: white;
                @media (max-width: 425px) {
                    display: none;
                }
            }
            h3 {
                @media (max-width: 567px) {
                    font-size: 0.8em;
                }
                background: white;
                display: inline-block;
                padding: 0.15em 0.5em 0.15em 0.3em;
                margin: 0;
            }
            @media (max-width: 1023px) {
                margin-left: 5px;
                width: calc( 50% - 2.5px);
                &:nth-child(2n+1) {
                    margin-left: 0;
                }
            }
            @media (min-width: 1024px) {
                margin-left: 5px;
                width: calc( 33.3% - 3.3333px);
                &:nth-child(3n+1) {
                    margin-left: 0;
                }
            }
        }
    }
}

// Topnews
.topNews {
    ul {
        @media(max-width: 1023px){
            border-bottom: solid 1px $c_divide;
            margin-bottom: pxtoem(26);
            padding-bottom: pxtoem(26);
        }
        li {
            a {
                display: block;
                text-decoration: none;
                color: $c_copy !important;
                padding-left: 38px;
                background: url("../images/Mehr.svg") no-repeat;
                background-size: 28px 28px;
                h4 {
                    margin-top: 0;
                }
            }
            border-top: solid 1px $c_divide;
            margin-top: pxtoem(26);
            padding-top: pxtoem(26);
            &:first-child {
                border: none;
                margin: 0;
                padding: 0;
            }
        }
    }
    &>p:last-child {
        padding-left: 38px;
        a {
            color: $c_copy;
            &:hover {
                color: $c_rot;
            }
        }
    }
}

// swiper
// Swiper
.swiperTop {
    width: 100%;
    &+article {
        margin-top: 3em;
    }
    .swiper-container {
        @media (min-width: 1280px) {
            max-width: 1140px;
        }
        margin: 0 auto;
        .swiper-slide {
            display: table;
            padding: 40% 0 5px;
            background: white;
            background-size: contain;
            background-repeat: no-repeat;
            a {
                color: $c_copy;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                text-decoration: none;
            }
            @media (min-width: 768px) {
                background-position: center;
                background-size: cover;
                padding: 2em 2em 38% 2em;
            }
            .tropfen {
                transform: translateX( -120%);
                h3 {
                    font-size: 2em;
                }
                @media (min-width: 768px) {
                    max-width: 450px;
                    position: absolute;
                    left: 2em;
                    top: 2em;
                }
                @media (max-width: 767px) {
                    margin: 0 20px 0;
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
                }
            }
            &.swiper-slide-active {
                .tropfen {
                    transform: translateX( 0);
                    transition: transform 0.5;
                }
            }
            &.swiper-slide-prev {
                .tropfen {
                    transform: translateX( 150%);
                    transition: all 1s;
                }
            }
            &.swiper-slide-next {
                .tropfen {
                    opacity: 0;
                    transform: translateX( -120%);
                }
            }
        }
    }
    .overlap {
        display: none;
        @media (min-width: 1280px) {
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX( 575px);
            width: calc( 50% - 575px);
            height: 0;
            background: $c_grau;
            background-size: cover;
            background-position: left center;
            opacity: 0.6;
            &:first-child {
                transform: none;
                left: 0;
                background-position: right center;
            }
        }
    }
    &+.wrap article,
    &+.wrap aside {
        margin-top: 50px;
    }
    div.padding {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding-bottom: 35.5%;
        @media (min-width: 768px) {
            padding-bottom: 41.5%;
        }
        pointer-events:none;
    }
    img.modulLogo {
        z-index: 99999;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 80px;
        @media (min-width: 768px) {
            right: 20px;
            bottom: 20px;
            width: 130px;
        }
        @media (min-width: 1024px) {
            width: 196px;
        }
    }
}

.swiper-pagination {
    bottom: 20px;
    .swiper-pagination-bullet {
        width: 13px;
        height: 13px;
        box-shadow: 0 0 4px rgba(black, 0.5);
        margin: 0 7px;
        background: white;
        opacity: 1;
        &.swiper-pagination-bullet-active {
            margin: 0 5.5px;
            background: $c_rot;
        }
    }
}

// History
div.history {
    h3 {
        color: $c_rot;
        margin: 1em 0;
        text-align: center;
        @media (min-width: 768px) {
            text-align: left;
            float: left;
            line-height: 1.1;
            margin: 0 0.3em 0 0;
            font-size: 2.7em;
        }
    }
    &.right {
        h3 {
            @media (min-width: 768px) {
                margin: 0 0 0 0.3em;
                float: right;
            }
        }
    }
}

// Zitate
section.quotes {
    position: relative;
    margin: 2em 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    .swiper-slide {
        padding: 20px;
        @media (min-width: 425px) {
            padding: 30px;
        }
        @media (min-width: 768px) {
            padding: 40px 60px;
        }
        text-align: center;
        img {
            margin: 0 0 2em;
            @media (min-width: 768px) {
                margin: 0;
                display: inline-block;
                vertical-align: middle;
            }
            width: 174px;
            height: 174px;
            border-radius: 100%;
        }
        blockquote {
            margin: 0;
            @media (min-width: 768px) {
                display: inline-block;
                vertical-align: middle;
                margin: 0 0 0 20px;
                width: calc( 100% - 200px);
            }
            text-align: left;
            font-size: 1em;
            max-width: none;
            p {
                font-size: 1em;
            }
        }
    }
    .quotes-control {
        margin: 0 0 20px;
        text-align: center;
        @media (min-width: 768px) {
            position: absolute;
            right: 20px;
            bottom: 20px;
            z-index: 9999;
        }
        div {
            display: inline-block;
            vertical-align: middle;
        }
        span,
        img {
            cursor: pointer;
        }
        .swiper-pagination {
            position: static;
        }
    }
}

/// Kontaktformular
section.contact {
    padding: 50px 0;
    background-size: cover;
    background-position: center;
    h2 {
        text-align: center;
        max-width: 888px;
        margin-left: auto;
        margin-right: auto;
    }
    .infobox {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.60);
    }
    .wp-block-contact-form-7-contact-form-selector,
    .form {
        form>div.field {
            margin: 0 0 1em;
            @media (min-width: 768px) {
                float: left;
                margin: 0 19px 0 0;
                max-width: 140px;
            }
            @media (min-width: 1024px) {
                max-width: 230px;
            }
            @media (min-width: 1440px) {
                max-width: 277px;
            }
            &.submit {
                margin-right: 0;
            }
            vertical-align: bottom;
            label {
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
            label>* {
                @media (max-width: 767px) {
                    width: 100%;
                }
                display: block;
                margin-top: 0.5em;
            }
            &.or {
                @media (min-width: 768px) {
                    padding-top: 1.5em;
                }
            }
        }
        &+h3 {
            margin: 0 0 0.5em;
        }
    }
    a {
        text-decoration: none;
    }
}

// Downloads
a.download {
    display: block;
    text-decoration: none;
    color: $c_copy;
    background: $c_grau_hell;
    &:hover {
        background: $c_grau;
    }
    margin: 0 0 1em 0;
    overflow: hidden;
    span {
        display: block;
        width: 150px;
        float: left;
        img {
            float: left;
        }
        &.info {
            width: calc( 100% - 150px);
            padding: 0.7em 1em;
        }
        &>p {
            font-size: 0.9em;
            margin: 0 0 0.5em 0;
            &:last-child {
                margin: 0;
                color: $c_rot;
                font-weight: bold;
            }
        }
    }
}