.wp-block-gg-tagcloud {
    margin-bottom: var(--m-vert);
    &>img {
        width: 100%;
        @media(max-width: 1023px){
            display: none;
        }
    }
    &>div {
        @media(min-width: 1024px){
            display: none;
        }
        .swiper-slide {
            div {
                width: 100%;
                padding-bottom: 100%;
                div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                &.tc2 div {
                    background-position: 33.333% top;
                }
                &.tc3 div {
                    background-position: 66.6666% top;
                }
                &.tc4 div {
                    background-position: right top;
                }
            }
        }
    }
}