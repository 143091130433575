@import "config.scss";

section#content {

	@media (max-width: 959px) {
		&>:first-child:not(.gg22) {
			margin-top: 35px;
		}
	}

}

body.home section#content {
	@media (max-width: 767px) {
		margin: 0;
	}
}

section > header {

	text-align: center;
	width: 100%;

	h2 + p {
		max-width: 680px;
		margin: 0 auto 2em auto !important;
		@extend .abstractRoot;
	}

}

@import "template-parts.scss";

// Content

article {

	@extend .typography;

	&.article {

		.moduleContent,
		&.has-sidebar {

			@media (min-width: 1024px) {
				float: right;
				width: calc( 100% - 360px );
			}

			&.reverse {
				@media (min-width: 1024px) {
					float: left;
				}
			}

		}

		ul {
			li {
				background: url("../images/Bullet.svg") no-repeat 0 7px;
				padding-left: 1.3em;
			}
		}

		dd,
		dt,
		ul,
		ol,
		p {
			margin: 0 0 1em 0;
			line-height: 1.5;
			font-weight: 300;
			overflow: hidden;
		}

		.moduleQuestion {

			margin: 1em 0 0 1.8em;

			p {
				font-weight: $fw_bold;
				margin: 0 0 0.5em;
			}

		}

	}

}

// Flexible

.flexible {

	margin-bottom: 2em;

	dd,
	dt,
	ul,
	ol,
	p {
		margin: 0 0 1em 0;
		line-height: 1.5;
		font-weight: normal;
	}

}

// Kacheln

section.tiles {

	margin-top: 50px;

	.inhaltWrap {

		position: relative;
		background-color: $c_grau_hell;
		height: 0;
		overflow: hidden;

		.wrap {
			padding: 50px 0;
			&.flexible {
				padding: 0;
			}
		}

	}

	margin-bottom: 50px;

}

.moduleManager {

	@media (max-width: 1023px) {
		display: none;
	}

	box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);

	width: 310px;
	float: left;

	& > * {
		margin-left: 1em !important;
		margin-right: 1em !important;
		&:first-child {
			margin: 0 !important;
		}
	}

	blockquote {
		margin-bottom: 1em;
	}

}

aside {

	&.next-to-article {

		@media (max-width: 1023px) {
			&:not(.keep-on-mobile) {
				display: none;
			}
		}

		@media(min-width: 1024px){
			width: 310px;
			float: left;
			&.reverse {
				float: right;
			}
		}


	}

	.asideBox,
	nav {
		background: white;
		box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
		border-radius: 0 26px 0 0;
		padding: 1em 0.9em;
		margin-bottom: 2em;
	}

	nav {
		h3 {
			font-size: pxtorem(22);
			@media (min-width: 560px) {
				font-size: pxtorem(24);
			}
			margin: 0 0 1em;
		}
		display: none;
		font-weight: $fw_bold;
		a {
			display: block;
			padding: 0.7em 0 0.7em 1.7em;
			text-decoration: none;
			color: $c_copy;
			background: url("../images/ArrowRechtsSolo.svg") no-repeat left center;
			background-size: 22px 22px;
			&:hover {
				background-image: url("../images/ArrowRechts.svg");
				color: $c_rot;
			}
		}
		li.current-menu-item {
			a {
				color: $c_rot;
			}
		}
	}

	&.has-nav {
		nav {
			display: block;
		}
	}

}

article.has-title + aside.next-to-article {
	margin-top: -5em;
}

body.search article.has-title + aside.next-to-article {
	margin-top: 0;
}

.gallery {
	.gallery-caption {
		font-size: 1em;
	}
}

.swiper-slide {
	.gallery-caption {
	    position: absolute;
	    right: 5px;
	    top: 5px;
	    background: white;
		padding: 2px 10px;
	}
}

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

// Terminübersicht

.widget_garant-date {
	margin-bottom: 0 !important;
}

.conferenceDate {

	margin: 0 0 1em;

	div.dateInner {
		&:first-child {
			margin: 0 0 0.5em;
		}
		p {
			margin-bottom: 0.5em;
			&:last-child {
				margin: 0;
			}
		}
	}

	@media (min-width: 616px ) {
		div.dateInner {
			width: calc( 100% - 150px );
			float: right;
			&:first-child {
				width: 150px;
				float: left;
			}
		}
	}

	@media (min-width: 1024px ) {
		div.dateInner {
			width: calc( 100% - 200px );
			&:first-child {
				width: 200px;
			}
		}
	}
}
