.wp-block-gg-linklist {
    hyphens: none;
    div {
        display: inline-block;
    }
    p {
        background: url("../images/Bullet.svg") no-repeat 0 6px;
        @media(min-width: 768px){
            background: url("../images/Bullet.svg") no-repeat 0 9px;
        }
        padding-left: 1.1em;
        a {
            text-decoration: none;
            color: var(--c-copy);
            strong {
                color: var(--c-corporate);
            }
            display: block;
            //padding-right: 48px;
            //background: url(../images/gg22/Pfeil.svg) no-repeat center right;
        }
        margin-bottom: 0.6em !important;
        display: inline-block;
        align-items: center;
    }
}