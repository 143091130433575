

@import "gg22/functions";
@import "gg22/fonts";
@import "gg22/properties";

body {
    scroll-behavior: smooth;
}

div#wrapall.gg22 {
    
    @import "gg22/header";
    @import "gg22/utilities";
    @import "gg22/ui";
    @import "gg22/typography";
    @import "gg22/article";
    @import "gg22/footer";
    @import "gg22/content";
    @import "gg22/blocks";
    @import "gg22/groups";
    @import "gg22/forms";

    font-family: "Maax";
    font-size: var(--fs-copy);

    header.gg22 {
        .wrap.gg22 {
            max-width: var(--max-width);;
        }
    }
}

footer.gg22 {
    @import "gg22/typography";

}
