.page-template-presse {
    
    aside {
        @media(max-width: 1023px){
            margin-top: 1em !important;
        }
        &.next-to-article {
            max-width: 100%;
            margin: 0 20px;
            @media(min-width: 667px){
                margin: 0 40px;
            }
            @media(min-width: 1024px){
                margin: 0;
                float: right;
            }
        }
    }

    @media (min-width: 1024px) {
        article.article .moduleContent, article.article.has-sidebar {
            float: left;
        }
    }

}

.proposts2col  {
    div.prposts {
        span.grid-item {
            @media(min-width: 424px){
                width: calc(50% - 10px);
            }
            margin-bottom: 20px !important;
            @media(min-width: 424px){
                display: flex;
                flex-direction: row-reverse;
                align-items: stretch;
            }
            .tn {
                width: 100%;
                padding-bottom: 100%;
                @media(min-width: 424px){
                    width: 33%;
                    padding: 0;
                }
                position: relative;
                flex-shrink: 0;
                a,img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &+div {
                flex-grow: 1;
            }
            &:not(:has(.tn)) {
                div {
                    width: 100%;
                }
            }
        }
    }
}

h3.accordion,
h3.pressarchive {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 20px;
    line-height: 1.2;
    min-height: 48px;
    border: solid 1px $c_grau;
    img {
        transition: transform 0.3s ease-in-out;
    }
    span {
        margin-left: 10px;
    }
    &+div {
        display: none;
    }
    &.open {
        img {
            transform: rotate(90deg);
            transition: transform 0.3s ease-in-out;
        }
        margin-bottom: 0;
        border-bottom: 0;
        &+div {
            padding: 20px;
            border: solid 1px $c_grau;
            border-top: 0;
            display: block;
            p {
                display: flex;
                span {
                    display: block;
                    width: 120px;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    }
}