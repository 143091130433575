.wp-block-gg-cta {
    @media(max-width: 1023px){
        display: none
    }
    position: fixed;
    z-index: 2015;
    right: 0;
    top: 50%;
    transform: translate(100%,-50%);
    transition: transform 400ms;
    &.show {
        transform: translate(calc(100% - 6.4em),-50%);
        transition: transform 400ms;
        &.full {
            transform: translate(0,-50%);
            transition: transform 400ms;
        }
    }
    padding: 0.86em;
    display: grid;
    gap: 1.2em;
    max-width: 300px;
    font-size: 0.7em;
    background: white;
    border: solid 1px $c_divide;
    border-width: 1px 0 1px 1px;
    border-left: solid 8px $c_rot;
    a {
        text-decoration: none;
        font-weight: bold;
    }
    div.head {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: $c_rot;
        padding-right: 40px;
        background: url("../images/gg22/Dropdown.svg") no-repeat right center;
        .image {
            flex-shrink: 0;
            display: block;
            position: relative;
            width: 4em;
            height: 4em;
            border-radius: 2em;
            overflow: hidden;
            margin-right: 1em;;
        }
    }
    div.click {
        display: none;;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 5.2em);
        height: 5.4em;
    }
    div.toggle {
        display: none;
    }
    &.show {
        div.click {
            display: block;
        }
    }
    &.open {
        div.toggle {
            display: block;
        }
        div.head {
            background: url("../images/gg22/Dropdown-Up.svg") no-repeat right center;
        }
    }
}