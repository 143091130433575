@import "config.scss";

footer {

	margin: pxtorem(60) 0 0;

	.inner {

		padding: pxtorem(60) 0;
		background: $c_grau_hell;
		overflow: hidden;

		.topNews {
			@media (min-width: 768px) {
				max-width: 330px;
				float: left;
			}
		}

		.divider {
			@media (max-width: 1023px) {
				display: none;
			}
		}

		#footerFeatures {
			margin-top: 50px;
			@media (min-width: 768px) {
				width: calc( 100% - 350px);
				float: right;
				margin: 0;
			}
			#topFeatures.two {
				display: grid;
				gap: var(--gap);
				@media(min-width: 768px){
					grid-template-columns: 1fr 1fr;
				}
				& > a {
					&.featureLarge > div {
						padding-bottom: 50%;
					}
				}
			}
			#topFeatures > a,
			& > a {
				display: block;
				position: relative;
				background-size: cover;
				color: $c_copy;
				text-decoration: none;
				box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
				& > h4 {
					position: absolute;
					left: 20px;
					bottom: 20px;
					width: 50%;
					span {
						background: white;
						display: inline-block;
						padding: 0.10em 0.4em 0.10em 0.4em;
						margin: 0.3em 0 0 0;
					}
				}
				&.featureLarge {
					& > div {
						width: 100%;
						padding-bottom: 32%;
						background-size: cover;
						background-position: center;
					}
					& > span {
						background: white;
						display: block;
						padding: 20px;
					}
				}
				&.featureSmall {
					margin: 30px 0;
					padding-bottom: 50%;
					h4 {
						margin: 0;
					}
					@media (min-width: 1024px) {
						padding-bottom: 25%;
						float: left;
						margin: 0 0 20px 0;
						width: calc( 50% - 10px );
						background: $c_divide;
						background-size: cover;
						&:nth-child(2n+1) {
							clear: left;
							margin-right: 20px;
						}
					}
				}

			}
		}

	}

	nav {

		background: $c_grau;
		text-align: center;
		padding: 1.5em 0 1.2em;

		font-size: pxtorem(15);

		#footerNavDesktop {
			@media (max-width: 767px) {
				display: none;
			}
		}

		#footerNavRwd {
			margin-bottom: 62px;
			@media (min-width: 768px) {
				display: none;
			}
		}

		ul {
			li {
				display: inline-block;
				margin: 0 1em;
				a {
					color: $c_copy;
					text-decoration: none;
					&:hover {
						color: $c_rot;
					}
				}
			}
		}

	}

}

#rwd_menu {
    @media (max-width: 767px) {
        z-index: 1000000;
        display: block;
        position: fixed;
        left: auto;
        right: 4px;
        bottom: auto;
        top: 4px;
        width: 50px;
        height: 50px;
        border-radius: 31px;
        border: none;
        background: white url("../images/Burger-22.svg") no-repeat center center;
        span {
            display: none;
        }
    }
}
#cookie-notice {
	z-index: 9999999;
}
