@import "config.scss";

body {
	font-weight: 300;
	strong {
		font-weight: 500;
	}
}

a {
	color: $c_rot;
	&:hover {
		color: $c_copy;
	}
	&.nodec {
		color: $c_copy;
		text-decoration: none;
		&:hover {
			color: $c_rot;
		}
	}
}

p {
	hyphens: auto;
}

.uc {
	background: fuchsia;
}

.divider {
	padding-top: 30px;
	border-top: solid 1px $c_divide;
	margin-top: 30px;
	display: block;
}

img.more {
	height: 1em;
	width: auto;
	margin: 0 0 0 0.3em;
	transform: translateY( 0.15em );
}


// Tropfen

.tropfen {

	display: inline-block;

	background: rgba( #ffffff, 0.9 );
	border-radius: 26px 0 26px 26px;
	padding: 1.5em;

	font-family: $ff_light;

	font-size: pxtoem(14);
	@media (min-width: 768px) {
		font-size: pxtoem(19);
	}
	line-height: 1.5em;

	h3 {
		font-size: pxtorem(38);
		letter-spacing: -1px;
		line-height: 1.25em;
	}

}
