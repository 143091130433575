.wp-block-gg-slider {
   .swiper-slide {
        .inner {
            position: relative;
            overflow: hidden;
            @media(min-width: 768px){
                border-radius: 0 0 pxtovw(200,1440) 0;
            }
            @media(min-width: 1440px){
                border-radius: 0 0 200px 0;
            }
            .image {
                position: relative;
                width: 100%;
                padding-bottom: 100%;
                @media(min-width: 768px){
                    padding-bottom: 50%;
                }
                @media(min-width: 1024px){
                    padding-bottom: 37.39%;
                }
            }
            .headline {
                hyphens: none;
                position: relative;
                z-index: 1986;
                margin: calc(-1 * var(--m-inner-vert)) calc(var(--gap-2x)) 0;
                padding: 1.5em;
                height: calc(100vw - 2 * var(--gap-2x));
                @media(min-width: 375px){
                    margin: calc(-1 * var(--m-inner-vert)) calc(1.5 * var(--gap-2x)) 0;
                    height: calc(100vw - 4 * var(--gap-2x));
                }
                @media(min-width: 768px){
                    padding: 31px 34px;
                    width: 260px;
                    height: 260px;
                    margin: 0;
                    position: absolute;
                    top: var(--m-inner-vert);
                    left: var(--m-inner-horz);
                    @media(min-width: 1024px){
                        width: 357px;
                        height: 357px;
                    }
                }
                background: var(--c-corporate);
                color: white;
                .h2 {
                    color: white;
                    font-size: pxtoem(36);
                    @media(min-width: 1024px){
                        font-size: pxtoem(40);
                    }
                    font-weight: bold;
                    line-height: 1;
                    margin-bottom: 0;
                    overflow: visible;
                }
                p {
                    hyphens: none !important;
                }
            }
            .logo {
                position: absolute;
                width: pxtovw(122,375);
                right: pxtovw(35);
                top: pxtovw(35);
                @media(min-width: 768px){
                    top: var(--m-inner-vert);
                    right: var(--m-inner-horz);
                    width: 160px;
                    @media(min-width: 1024px){
                        width: 218px;
                    }
                }
            }
        }
    }
}

.acf-block-preview {
    .wp-block-gg-slider {
        .swiper-slide:not(:first-child) {
            display: none;
        }
    }
}