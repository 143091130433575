    .wp-block-contact-form-7-contact-form-selector,
    .form {
        form>div.field {
            margin: 0 0 1em;
            @media (min-width: 768px) {
                float: left;
                margin: 0 19px 0 0;
                max-width: 140px;
            }
            @media (min-width: 1024px) {
                max-width: 230px;
            }
            @media (min-width: 1440px) {
                max-width: 277px;
            }
            &.submit {
                margin-right: 0;
            }
            vertical-align: bottom;
            label {
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
            label>* {
                @media (max-width: 767px) {
                    width: 100%;
                }
                display: block;
                margin-top: 0.5em;
            }
            &.or {
                @media (min-width: 768px) {
                    padding-top: 1.5em;
                }
            }
        }
        &+h3 {
            margin: 0 0 0.5em;
        }
    }
