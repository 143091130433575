.sticky {
    z-index: 1;
}

.bypostauthor {
    z-index: 1;
}

div.blogposts,
div.prposts,
article.blog {
    span.grid-item,
    a.grid-item {
        display: block;
        border: solid 1px $c_grau;
        text-decoration: none;
        width: 100%;
        @media (min-width: 768px) {
            width: calc( 33% - 10px);
        }
        margin-bottom: 10px;
        padding: 1px;
        &:hover {
            background: $c_grau;
            cursor: pointer;
        }
        div:not(.tn) {
            padding: 1em;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        h3,
        h4,
        p {
            hyphens: auto;
        }
        p {
            font-size: 0.8em;
            color: $c_copy;
            &.more {
                color: $c_rot;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &.full {
            width: auto;
        }
        p+h4 {
            margin-top: 0;
        }
        p.more {
            display: inline-block;
            margin: 0;
            a {
                display: inline-block;
                padding: 0.6em 0;
            }
            &:last-child {
                margin-top: auto;
            }
        }
        p.downloads {
            overflow: hidden;
            margin: 0.5em 0 0;
            @media (min-width: 768px) {
                float: right;
                margin: 0;
            }
            a.download {
                display: block;
                margin: 0.5em 0 0 0;
                @media (min-width: 768px) {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 0 0 1em;
                }
                padding: 0.6em 0.7em 0.6em 3em;
                background-repeat: no-repeat;
                background-position: 0.3em center;
                background-size: 24px 28px;
                &.pdf {
                    background-image: url("../images/files/pdf.png");
                }
                &.doc,
                &.docx {
                    background-image: url("../images/files/doc.png");
                }
                &.jpg,
                &.jpeg {
                    background-image: url("../images/files/jpg.png");
                }
            }
        }
    }
    span.grid-item:hover {
        background: inherit;
        cursor: inherit;
    }
    &.type-presse {
        a.grid-item {
            p:first-child {
                margin-bottom: 0;
            }
            width: auto;
        }
    }
}

#skim {
    margin: 50px 0 0;
    display: flex;
    &.blog {
        margin: 20px 0 0;
    }
    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        padding: 0 20px;
        a {
            cursor: pointer;
            text-indent: -50000px;
            display: block;
            width: 28px;
            height: 28px;
            background: url("../images/ArrowRechts.svg") no-repeat;
            background-size: contain;
        }
        &:first-child {
            justify-content: flex-end;
            a {
                background-image: url("../images/ArrowLinks.svg");
            }
        }
    }
}

div.blogposts {
    margin-bottom: 2em;
    div.swiper-wrapper {
        align-items: stretch;
        .grid-item {
            height: auto;
        }
    }
}

.share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4 {
        text-align: center;
    }
    div {
        display: flex;
        justify-content: center;
        a {
            margin: 0 0.5em;
        }
    }
}