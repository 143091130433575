@import "config.scss";

.typography {

	b,strong {
		font-weight: $fw_bold;
	}

	i,em {
		font-style: italic;
	}

	blockquote,
	pre {
		padding: 8px 10px;
	}

	blockquote {
		max-width: 660px;
		margin:  2em auto;
		background: url("../images/Quote.svg") no-repeat;
		line-height: 1.5;
		font-size: pxtorem(24);
		p:last-child {
			font-size: 1rem;
			margin: 0;
		}
	}

	pre {
		font-family: $ff_fixed;
		line-height: 1.5;
		background: $c_grau;
		color: white;
		padding: 1.5rem;
	}

	dd,
	dt,
	ul,
	ol,
	p {
		margin: 0 0 1em 0;
		line-height: 1.5;
	}

	.abstract {
		@extend .abstractRoot;
	}

	p:last-child {
		margin-bottom: 0;
	}

	ul {
		li {
			margin-top: 10px;
			&:nth-child {
				margin: 0;
			}
		}
		ul {
			margin-left: 1em;
		}
	}

	ol li {
		list-style-type: decimal;
		margin-left: 30px;
	}

	ul,
	ol {
		margin: 1em 0;
		line-height: 1.4;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0 0 1em;
		line-height: 1.4;
		color: $c_head;
		a {
			text-decoration: none;
			color: $c_copy;
			&:hover {
				color: black;
			}
		}
	}

	* + h1,
	* + h2,
	* + h3,
	* + h4 {
		margin-top: 2em;
	}


	h1 {
		font-size: pxtorem(32);
		@media (min-width: 560px) {
			font-size: pxtorem(48);
		}
		margin: 0;
		& + * {
			margin-top:  2em;
		}
		& + h3 {
			margin-top: 0.5em;
		}

	}

	h2 {
		font-size: pxtorem(24);
		margin: 0 0 1em;
		@media (min-width: 560px) {
			font-size: pxtorem(34);
		}
		color: $c_head;
	}

	h3 {
		font-size: pxtorem(22);
		@media (min-width: 560px) {
			font-size: pxtorem(24);
		}
	}

	h4,
	h5 {
		font-size: pxtoem(19);
		margin-bottom: 0.5em;
	}

	.top + *,
	.coltop + *,
	a[name="copytop"] + * {
		margin-top: 0;
	}

	h1:last-child,
	h2:last-child,
	h3:last-child,
	h4:last-child {
		margin-bottom: 0;
	}

	a:not([class]) {
		color: $c_link;
		&:hover {
			color: $c_head;
			&:active {
				color: $c_link;
			}
		}
	}

	.wp-caption p {
		font-size: 0.8em;
		margin: 0.5em 0 1em 0;
	}

}

.abstractRoot {
	font-size: pxtoeem(18);
	@media (min-width: 768px) {
		font-size: pxtorem(22);
	}
}
