@import "config.scss";

html {
  scroll-behavior: smooth;
	zoom: 100%;
	* {
		box-sizing: border-box;
	}
	box-sizing: border-box;
}

*, *:before, *:after {

  box-sizing: inherit;

}

body {

	scroll-behavior: smooth;

	font-family: $ff_copy;
	-webkit-font-smoothing: antialiased;

	font-size: 15px;

	position: relative;

	@media (min-width: 1024px) {
		font-size: 17px;
	}

	@media (min-width: 1280px) {
		font-size: 19px;
	}

	z-index: 0;
	@media (min-width: 768px) {
		z-index: 768
	}
	@media (min-width: 1024px) {
		z-index: 1024
	}

}

.panel-grid.panel-no-style,
.wrap {

	margin-left: auto !important;
	margin-right: auto !important;

	position: relative;

	padding: 0 20px;

	@media (min-width: 667px) {
		padding: 0 40px;
	}

	@media (min-width: 1024px) {
		padding: 0;
		max-width: 980px;
	}
	@media (min-width: 1280px) {
		max-width: 1140px;
	}

	&.siteorigin-panels-stretch {
		margin: inherit !important;
		max-width: none !important;
	}

}

#wrapall {

	margin: 0 auto;

}
