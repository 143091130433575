.wp-block-gg-zitate {
    position: relative;
    padding: 2em 0;
    @media(min-width: 1024px){
        padding: 2em var(--m-vert);
    }
    margin-bottom: var(--m-vert);
    @media(min-width: 768px){
        border: solid 1px var(--c-hellgrau);
        border-width: 1px 0;
    }
    &>p {
        font-weight: bold;
        color: var(--c-corporate);
        margin: 0;
        @media(min-width: 1024px){
            text-align: right;
            position: absolute;
            right: var(--m-vert);
            top: 0;
        }
    }
    
    .inner {
        @media(max-width: 767px){
            border: solid 1px var(--c-hellgrau);
            border-width: 1px 0;
        }
        @media(min-width: 768px){
            margin-top: 2em;
            display: grid;
            gap: var(--m-vert-slim);
            align-items: end;
            grid-template-columns: calc(260px + var(--gap)) 1fr;
        }
        .bild {
            position: relative;
            left: 1px;
            @media(max-width: 767px){
                padding: 11vw;
            }
            @media(min-width: 768px){
                padding: 0;
                padding-right: var(--gap);
                padding-bottom: var(--m-vert-slim);
            }
            .pr+img {
                position: absolute;
                width: pxtovw(111);
                right: pxtovw(20);
                bottom: pxtovw(20);
                @media(min-width: 768px){
                    width: 100px;
                    right: 0;
                    bottom: 0;
                }
                @media(min-width: 1024px) {
                    width: auto;
                }
            }
        }
        .zitat {
            padding-top: 2em;
            padding-bottom: var(--m-vert-slim);
            @media(min-width: 1024px){
                padding-right: var(--m-vert);
            }
        }
    }

    .swiper-pagination {
        @media(min-width: 768px){
            position: absolute;
            right: var(--m-vert-slim);
            bottom: 2em;
            width: auto;
            text-align: right;
        }
    }

}

.wp-block-group.has-background+.wp-block-gg-zitate {
    border-top: none;
    margin-top: var(--m-vert);
}