:root {
    
    // Colours
    --c-copy: #2A2A2A;
    --c-corporate: #E3000B;
    --c-rot: #E3000B;
    --c-mittelgrau: #c9caca;
    --c-hellgrau: #eeeeee;
    --c-head: var(--c-copy);
    --c-link: var(--c-corporate);
    
    // Fonts
    font-size: 87%;
    @media(min-width: 1024px){
        --fs-copy: 113%;
    }
    --fs-large: #{pxtoem(22)};
    --fs-small: #{pxtoem(16)};
    --ff-copy: "Maax",sans-serif;
    --ff-head: sans-serif;
    --ff-fixed: sans-serif;
    
    // Columns

    --wp--style--block-gap: 59px;
    
    // Widths
    --max-width: calc(100% - 4em);
    --article-width: var(--max-width);
    
    @media(min-width: 768px){
        --article-width: 768px;
    }
    @media(min-width: 1440px){
        --max-width: 1380px;
    }

    @media(min-width: 1280px) {
        --article-width: 960px;
    }

    --width-margin: calc(50% - 0.5 * var(--max-width));
    --width-margin-article: calc(50% - 0.5 * var(--article-width));


    --article-width-slim: 960px;

    // Margins
    --m-vert: #{pxtovw(40)};
    --m-vert-slim: #{pxtovw(28)};
    --gap: 1.25rem;
    --gap-2: 2.5rem;
    --m-inner-horz: 28px;
    --m-inner-vert: 24px;
    @media(min-width: 1024px){
        --m-vert: 76px;
        --m-vert-slim: 58px;
        --m-inner-horz: 56px;
        --m-inner-vert: 48px;
    }


    // Calcs
    --m-vert-2x: calc(2 * var(--m-vert));
    --gap-2x: calc(2 * var(--gap));
    --gap-4x: calc(4 * var(--gap));
    --gap-6x: calc(6 * var(--gap));
    // Radius
    --radius: #{pxtorem(20)};
    --radius-small: #{pxtorem(5)};
    // Schatten
    --shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    --shadow-flat: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }