@import "config.scss";

.columns {
	clear: both;
	width: 100%;
	overflow: hidden;
}

.col {
	margin-bottom: 20px;
	@media (min-width: 768px) {
		width: calc( 50% - 25px );
		margin-bottom: 20px;
		float: left;
		margin-left: 50px;
		&.span2 {
			width: 100%;
			margin-left: 0;
			float: none;
		}
	}
	p:last-child {
		margin-bottom: 0 !important;
	}
	&.first {
		clear: both;
		margin-left: 0;
	}
}

.col3 {
	margin-bottom: 20px;
	@media (min-width: 768px) {
		padding: 0;
		width: calc( 33% - 13.333px );
		float: left;
		margin-left: 20px;
		&.span2 {
			width: calc( 100% - 33% - 13.333px );
		}
	}
	p:last-child {
		margin-bottom: 0 !important;
	}
	&.first {
		clear: both;
		margin-left: 0;
	}
}

.col4 {
	padding: 0 30px;
	@media (min-width: $ww_article) {
		padding: 0;
		width: ($ww_article - 3 * $ww_gap)/4;
		margin-bottom: $ww_gap;
		float: left;
		margin-left: $ww_gap;
		&.span2 {
			width: 2 * (($ww_article - 3 * $ww_gap)/4) + $ww_gap;
		}
		&.span3 {
			width: 3 * (($ww_article - 3 * $ww_gap)/4) + 2 * $ww_gap;
		}
	}
	p:last-child {
		margin-bottom: 0 !important;
	}
	&.first {
		clear: both;
		margin-left: 0;
	}
}
