section.wp-block-group:not(.wide).has-background {
    padding: var(--m-vert) calc(var(--width-margin) + var(--m-vert));
}

section.wp-block-group.wide.has-background {
    padding: var(--m-vert) 0;
}

div.wp-block-group.has-background {
    padding: var(--gap-2x) !important;
}

section.wp-block-group.has-background {
    padding: var(--m-vert) calc(var(--width-margin) + var(--m-vert));
}

.wp-block-group.has-df-weiss-color {
    h1,
    h2,
    h3 {
        color: white;
    }
    a {
        color: white;
        &:hover {
            color: white;
        }
    }
}